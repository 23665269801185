var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "cameras") ||
    _vm.$can("update", "camerasmanagement")
    ? _c(
        "div",
        [
          _c("client-sites-list-add-new", {
            attrs: {
              "is-add-new-client-sites-sidebar-active":
                _vm.isAddNewClientSitesSidebarActive,
              "client-name-prop": _vm.cameraData.clientname,
              "client-display-name-prop": _vm.cameraData.client_display_name
            },
            on: {
              "update:isAddNewClientSitesSidebarActive": function($event) {
                _vm.isAddNewClientSitesSidebarActive = $event
              },
              "update:is-add-new-client-sites-sidebar-active": function(
                $event
              ) {
                _vm.isAddNewClientSitesSidebarActive = $event
              },
              "refetch-data": _vm.refetchData,
              newSiteAdded: _vm.refreshClientSitesList
            }
          }),
          _c(
            "b-media",
            {
              staticClass: "mb-2",
              scopedSlots: _vm._u(
                [
                  {
                    key: "aside",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                647001971
              )
            },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "CameraIcon", size: "22" }
                  }),
                  _vm._v(
                    " Camera Settings -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              !_vm.cameraData.is_active
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-danger" }
                    },
                    [_vm._v(" Disabled ")]
                  )
                : !_vm.cameraData.is_online
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize",
                      attrs: { pill: "", variant: "light-warning" }
                    },
                    [_vm._v(" Offline ")]
                  )
                : _vm._e(),
              _c("br")
            ],
            1
          ),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _vm.userData.group === "Management"
                            ? _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [_vm._v("General Settings")])
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userData.group === "Management"
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Camera ID",
                                            "label-for": "camera_id"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "camera_id",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "camera_id",
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                          readonly: true,
                                                          name: "camera_id"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .camera_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "camera_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.camera_id"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Unit Number",
                                            "label-for": "unit_number"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "unit_number",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "unit_number",
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                          readonly: true,
                                                          name: "unit_number"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .unit_number,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "unit_number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.unit_number"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Year Built",
                                            "label-for": "year_built"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "year_built",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.yearBuiltList,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "year_built"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .year_built,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "year_built",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.year_built"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Unit ID",
                                            "label-for": "unit_id"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: { name: "unit_id" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "unit_id",
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                          name: "unit_id"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .unit_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "unit_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.unit_id"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Controller BOM",
                                            "label-for": "controller_bom"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "controller_bom",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.controllerBOMOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "controller_bom"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .controller_bom,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "controller_bom",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.controller_bom"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "IMEI",
                                            "label-for": "imei"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "imei",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: { id: "imei" },
                                                        model: {
                                                          value:
                                                            _vm.cameraData.imei,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "imei",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.imei"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Camera Type",
                                            "label-for": "camera_type"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "camera_type",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.cameraTypeOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "camera_type"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .camera_type,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "camera_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.camera_type"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Device Path (*PI)",
                                            "label-for": "device_path"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "device_path",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.devicePathOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "device_path"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .device_path,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "device_path",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.device_path"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Power Option",
                                            "label-for": "options_power"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "options_power",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.powerOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "options_power"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .options.power,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .options,
                                                              "power",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.options.power"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Has Pan / Tilt (PTZ)",
                                            "label-for": "has_pan_tilt"
                                          }
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              name: "check-button",
                                              switch: "",
                                              inline: ""
                                            },
                                            model: {
                                              value:
                                                _vm.cameraData.options
                                                  .has_pan_tilt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData.options,
                                                  "has_pan_tilt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cameraData.options.has_pan_tilt"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Phone",
                                            "label-for": "phone"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "phone",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: { id: "phone" },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .phone,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "phone",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.phone"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Cell Provider",
                                            "label-for": "cell_phone_carrier"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "cell_phone_carrier"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.cellProviderOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "cell_phone_carrier"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .cell_phone_carrier,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "cell_phone_carrier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.cell_phone_carrier"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Control Settings")])
                              ])
                            ],
                            1
                          ),
                          _vm.userData.group === "Management" &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Image Delay",
                                            "label-for": "image_delay"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "image_delay",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.imageDelayOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "image_delay"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .configuration
                                                              .image_delay,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .configuration,
                                                              "image_delay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.configuration.image_delay"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Image Resolution",
                                            "label-for": "image_resolution"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "image_resolution",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.imageResolutionOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "image_resolution"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .configuration
                                                              .image_resolution,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .configuration,
                                                              "image_resolution",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.configuration.image_resolution"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userData.group === "Management" &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Video Length",
                                            "label-for": "image_delay"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "video_length",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.videoLengthOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "video_length"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .configuration
                                                              .video_length,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .configuration,
                                                              "video_length",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.configuration.video_length"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Video Resolution",
                                            "label-for": "video_resolution"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "video_resolution",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.videoResolutionOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "video_resolution"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .configuration
                                                              .video_resolution,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .configuration,
                                                              "video_resolution",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.configuration.video_resolution"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userData.group === "Management" &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Motion Detection",
                                            "label-for":
                                              "is_motion_detection_enabled"
                                          }
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              name: "check-button",
                                              switch: "",
                                              inline: ""
                                            },
                                            model: {
                                              value:
                                                _vm.cameraData.settings
                                                  .is_motion_detection_enabled,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData.settings,
                                                  "is_motion_detection_enabled",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cameraData.settings.is_motion_detection_enabled"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.cameraData.options.has_defender ||
                            _vm.userData.group === "Management") &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Has Defender",
                                            "label-for": "has_defender"
                                          }
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              name: "check-button",
                                              switch: "",
                                              inline: ""
                                            },
                                            model: {
                                              value:
                                                _vm.cameraData.options
                                                  .has_defender,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData.options,
                                                  "has_defender",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cameraData.options.has_defender"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.cameraData.options.has_defender
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Defender Active",
                                                "label-for":
                                                  "is_defender_enabled"
                                              }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: "",
                                                  inline: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.settings
                                                      .is_relay3,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData.settings,
                                                      "is_relay3",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.settings.is_relay3"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _vm.userData.group === "Management"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Content Retention",
                                            "label-for": "expiry_days"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "expiry_days",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.expiryDaysOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "expiry_days"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .expiry_days,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "expiry_days",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.expiry_days"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " This setting change only effects any new content going forward. Previously saved content is not effected by this change. "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              (_vm.cameraData.is_active ||
                                _vm.userData.group === "Management") &&
                              _vm.cameraData.is_online
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Time Zone",
                                            "label-for": "time_zone"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "time_zone",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.timeZoneOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "time_zone"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .time_zone,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "time_zone",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.time_zone"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.userData.group === "Management" &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Heartbeat",
                                            "label-for": "heartbeat_interval"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "heartbeat_interval",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.heartbeatIntervalOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          readonly: true,
                                                          "input-id":
                                                            "heartbeat_interval"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .settings
                                                              .heartbeat_interval,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData
                                                                .settings,
                                                              "heartbeat_interval",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.settings.heartbeat_interval"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userData.group === "Management"
                            ? _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [
                                      _vm._v("Client Information and Notes")
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _vm.userData.group === "Management"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Client",
                                            "label-for": "clientname"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "clientname",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.clientsList,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "clientname"
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.fetchClientSitesForSettings
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .clientname,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "clientname",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.clientname"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.cameraData.is_active ||
                              _vm.userData.group === "Management"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Client Site",
                                            "label-for": "sitename"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "sitename",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.clientSitesList,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          "input-id": "sitename"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .sitename,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "sitename",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.sitename"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.$can("update", "clients")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center justify-content-end"
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: { variant: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isAddNewClientSitesSidebarActive = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "UserPlusIcon"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-nowrap ml-1"
                                                    },
                                                    [_vm._v("Add Site")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.cameraData.is_active ||
                              _vm.userData.group === "Management"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Location",
                                            "label-for": "location_name"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "location_name",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "location_name"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .location_name,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "location_name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.location_name"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.userData.group === "Management"
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Customer Info",
                                            "label-for": "customer_info"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: { name: "customer_info" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-textarea", {
                                                        attrs: {
                                                          id: "customer_info",
                                                          rows: "3",
                                                          "max-rows": "6"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData
                                                              .customer_info,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "customer_info",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.customer_info"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Notes",
                                            "label-for": "note"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "note",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-textarea", {
                                                        attrs: {
                                                          id: "note",
                                                          rows: "3",
                                                          "max-rows": "6"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.cameraData.note,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.cameraData,
                                                              "note",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "cameraData.note"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.userData.group === "Management" &&
                          _vm.cameraData.is_online
                            ? _c(
                                "b-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Status",
                                            "label-for": "isActiveSetter"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "isActiveSetter",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          dir: _vm.$store.state
                                                            .appConfig.isRTL
                                                            ? "rtl"
                                                            : "ltr",
                                                          options:
                                                            _vm.statusOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "isActiveSetter"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.isActiveSetter,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.isActiveSetter = $$v
                                                          },
                                                          expression:
                                                            "isActiveSetter"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _vm.cameraData.is_active ||
                              _vm.userData.group === "Management" ||
                              _vm.userData.group === "ClientAdmins"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _vm.$can("update", "cameras") ||
                                      _vm.$can("update", "camerasmanagement")
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: "",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Save Changes ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.userData.group === "Management"
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _vm.$can("delete", "cameras") ||
                                      _vm.$can("delete", "camerasmanagement")
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "danger",
                                                block: ""
                                              },
                                              on: { click: _vm.deleteCamera }
                                            },
                                            [_vm._v(" Delete Camera ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              4242329695
            )
          }),
          _c(
            "b-modal",
            {
              ref: "vmodal",
              attrs: {
                id: "vmodal",
                "ok-only": "",
                "ok-title": "Close",
                centered: "",
                size: "lg",
                title: "Unit Number Error"
              },
              on: {
                close: function($event) {
                  _vm.showErrorModal = false
                }
              }
            },
            [
              _vm._v(
                " The Unit ID must be a integer from 1 to 2999 or the same as the Camera ID. "
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }